import "core-js/modules/es.array.push.js";
import axios from 'axios';
import { Empty, Image as VanImage, Button, Overlay, Loading, Cell, CellGroup, Grid, GridItem, Sticky, Tag, RadioGroup, Radio, Divider, Switch, Swipe, SwipeItem, TextEllipsis, showToast, showLoadingToast, showImagePreview, Dialog, showConfirmDialog, BackTop, FloatingBubble } from 'vant';
export default {
  data() {
    return {
      bannerList: [],
      storeList: [],
      myAddress: "正在定位中，如无反应请点我刷新！",
      showcps: true,
      showCanRob: false,
      switchType: 1,
      mtCPS: "https://scg-1251314318.cos.ap-guangzhou.myqcloud.com/otherphoto/738cdae8528d7f684629925fc72ac895.JPG",
      elemeCPSIMG: "https://file.sct.qaqwq.com/indexImg/b4ecd06f593123c6dda457ae037d1e5d.jpeg",
      didiCPSIMG: "https://file.sct.qaqwq.com/indexImg/bc92d67abf787966b4f40d4b3abd74d1.png"
    };
  },
  methods: {
    setup() {
      console.log("123");
      const active = ref(0);
      return {
        active
      };
    },
    navigatocps(e) {
      if (e == 'mt') {
        showImagePreview([this.mtCPS]);
      }
      if (e == 'eleme') {
        showImagePreview([this.elemeCPSIMG]);
      }
      if (e == 'didi') {
        showImagePreview([this.didiCPSIMG]);
      }
      if (e == 'qa') {
        window.location.href = 'https://sct.qaqwq.com/gotowechat';
      }
    },
    navigatosku(id, dis) {
      this.$router.push({
        name: 'skudetail',
        params: {
          skuid: id,
          dis: dis
        }
      });
    }
  },
  mounted() {
    var that = this;
    //获取地理位置
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(showPosition);
    } else {
      alert("该浏览器不支持Geolocation。");
    }
    function showPosition(position) {
      var latitude = position.coords.latitude;
      var longitude = position.coords.longitude;
      // 对获取到的位置信息进行处理
      axios.get('https://sct.qaqwq.com/api/xcx/getMyAddress?latitude=' + latitude + '&longitude=' + longitude, {
        headers: {
          'Content-Type': 'application/json',
          'token': localStorage.getItem('token')
        }
      }).then(res => {
        if (res.data.code == 201) {
          that.myAddress = res.data.data;
        }
      });
      axios.get('https://sct.qaqwq.com/api/xcx/getConfig', {
        headers: {
          'Content-Type': 'application/json',
          'token': localStorage.getItem('token')
        }
      }).then(res => {
        that.mtCPS = res.data.mtCPS, that.elemeCPSIMG = res.data.elemeCPSIMG, that.didiCPSIMG = res.data.didiCPSIMG, that.bannerList = res.data.indexImg;
      });
      axios.get('https://sct.qaqwq.com/api/xcx/getStoreList?latitude=' + latitude + '&longitude=' + longitude, {
        headers: {
          'Content-Type': 'application/json',
          'token': localStorage.getItem('token')
        }
      }).then(res => {
        that.storeList = res.data;
      });
      showConfirmDialog({
        title: '提醒',
        message: '网页版正在维护中，后期会加入更多功能\n欢迎打开小程序端以获得更好的体验！\n\n微信搜索小程序【餐餐试吃团】'
      }).then(() => {
        window.location.href = 'https://sct.qaqwq.com/gotowechat';
      });
    }
  }
};